import React, { useState, useEffect, useCallback } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';
import { styled } from '@compiled/react';

import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { withFlags } from '@confluence/flags';
import { fg } from '@confluence/feature-gating';
import { DocumentSpaceTitle } from '@confluence/document-title';
import { FixedSpaceHeaderContainer } from '@confluence/breadcrumbs';
import type { WithFlagsProps } from '@confluence/flags';
import {
	useRouteActions,
	createSingleQueryParamHook,
} from '@confluence/route-manager/entry-points/RouteState';

import { ChangeHubNameDialog } from '../change-hub-name-dialog';

const styles = xcss({ minHeight: '72px' });
const COMPANY_HUB_SUCCESS_FLAG_ID = 'company-hub.first-time.success.flag';

// eslint-disable-next-line check-i18n-id-prefix/i18n-id-prefix-with-package-name-defineMessages -- To not throw away existing translations when the concept "Company Home Builder" was obsoleted
const i18n = defineMessages({
	flagSuccessMessageTitle: {
		id: 'space-overview.company-hub.success-flag-message.title',
		defaultMessage: 'Here’s a template to get you started',
		description:
			'Success flag title message that users will see when they land in their company hub landing page for the first time',
	},
	flagSuccessMessageDescription: {
		id: 'space-overview.company-hub.success-flag-message.description',
		defaultMessage: 'Turn this landing page into an information hub for your entire company.',
		description:
			'Success flag description message that users will see when they land in their company hub landing page for the first time',
	},
	flagSuccessMessageCloseButton: {
		id: 'space-overview.company-hub.success-flag-message.close-button',
		defaultMessage: 'Got it',
		description: 'Button that allows users to dismiss the flag message',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CompanyHubHeaderContainer = styled.div({
	display: 'flex',
	padding: `${token('space.250', '20px')}`,
	justifyContent: 'flex-end',
	position: 'absolute',
	right: 0,
});

const SpaceHeaderPlaceholder = () => (
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	<Box xcss={styles} testId="space-header-placeholder" />
);

const useQueryParamFirstTime = createSingleQueryParamHook('redirect');

type HubToolbarButtonsProps = {
	editButton: React.ReactNode;
	spaceKey: string;
	versionOverride?: number | null;
	spaceOverviewQueryLoading: boolean;
	isThemed?: boolean;
	isSpaceAdmin?: boolean;
	hasEditPermissions?: boolean;
} & WithFlagsProps;

export const HubToolbarButtons = withFlags<HubToolbarButtonsProps>(
	({
		editButton,
		spaceKey,
		spaceOverviewQueryLoading,
		isThemed,
		isSpaceAdmin,
		hasEditPermissions,
		flags,
	}) => {
		const { setQueryParams } = useRouteActions();
		const isFirstTimeRedirect = useQueryParamFirstTime() === 'first-time';

		const dismissFlag = useCallback(() => {
			void flags.hideFlag(COMPANY_HUB_SUCCESS_FLAG_ID);
		}, [flags]);

		const isFirstTimeRedirectedSpaceAdmin = Boolean(
			isFirstTimeRedirect &&
				isSpaceAdmin &&
				hasEditPermissions &&
				!fg('confluence_frontend_company_hub_ga_updates'),
		);

		const [isChangeHubNameDialogOpen, setIsChangeHubNameDialogOpen] = useState<boolean>(
			isFirstTimeRedirectedSpaceAdmin,
		);

		useEffect(() => {
			setIsChangeHubNameDialogOpen(isFirstTimeRedirectedSpaceAdmin);
		}, [isFirstTimeRedirectedSpaceAdmin]);

		const handleOnClose = useCallback(() => {
			setIsChangeHubNameDialogOpen(false);
			setQueryParams({ redirect: undefined }, true);
			void flags.showSuccessFlag({
				title: <FormattedMessage {...i18n.flagSuccessMessageTitle} />,
				description: <FormattedMessage {...i18n.flagSuccessMessageDescription} />,
				id: COMPANY_HUB_SUCCESS_FLAG_ID,
				actions: [
					{
						content: <FormattedMessage {...i18n.flagSuccessMessageCloseButton} />,
						onClick: dismissFlag,
					},
				],
			});
		}, [dismissFlag, flags, setQueryParams]);

		if (!hasEditPermissions) {
			return null;
		}

		if (spaceOverviewQueryLoading && !fg('confluence_frontend_company_hub_ga_updates')) {
			return <SpaceHeaderPlaceholder />;
		}

		return (
			<>
				{fg('confluence_frontend_company_hub_ga_updates') ? (
					<CompanyHubHeaderContainer>{editButton}</CompanyHubHeaderContainer>
				) : (
					<FixedSpaceHeaderContainer isThemed={isThemed} justifyContent="flex-end">
						{editButton}
					</FixedSpaceHeaderContainer>
				)}
				{isChangeHubNameDialogOpen && (
					<ChangeHubNameDialog handleFirstTimeDialogClose={handleOnClose} />
				)}
				<DocumentSpaceTitle spaceKey={spaceKey} />
			</>
		);
	},
);
